<template>
    <section class="content">
        <div class="card" style="display: none;">
            <div class="card-header">
                Filter Pencarian
            </div>
            <div class="card-body row">
                <div class="col-md-3 form-group">
                    <label class="control-label">Nama Tentor</label>
                    <input type="hidden" name="row_id" value="" />
                    <input id="f_tentor" class="form-control f_tentor" v-model="form.f_tentor" type="text" required="required" name="mapel" />
                </div>
                <div class="col-md-3 form-group">
                    <label class="control-label">Kelas</label>
                    <input id="f_kelas" class="form-control f_kelas" v-model="form.f_kelas" type="text" required="required" name="kelas" />
                </div>
                <div class="col-md-3 form-group">
                    <label class="control-label">Mata Pelajaran</label>
                    <input id="f_pelajaran" class="form-control f_pelajaran" v-model="form.f_pelajaran" type="text" required="required" name="akademik" />
                </div>
                <div class="col-md-3 form-group">
                    <label class="control-label">Tanggal</label>
                    <datepicker
                      placeholder="Tanggal"
                      v-model="form.f_tanggal"
                      required
                    />
                </div>
            </div>
            <div class="card-footer">
                <button type="submit" v-on:click="search" class="btn btn-primary btn-md float-right">Cari</button>
            </div>
        </div>
        <table class="table table-hover" ref="tableuser">
            <thead>
                <tr>
                    <th>Kode</th>
                    <th>Hari</th>
                    <th>Tanggal</th>
                    <th>Pukul</th>
                    <th>Materi</th>
                    <th>Keterangan</th>
                </tr>
            </thead>
            <tbody @click="handleClick">
            </tbody>
        </table>
        <div class="modal" tabindex="-1" role="dialog" ref="formDialog" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <form role="form" @submit="submitForm">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ formTitle }}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <p v-if="errors.length" class="alert alert-danger">
                                <b>Please correct the following error(s):</b>
                                <ul>
                                    <li v-for="error in errors">{{ error }}</li>
                                </ul>
                            </p>
                            <div class="row">
                                <div class="col-md-8 form-group">
                                    <input type="hidden" name="row_id" value="" />
                                </div>
                                <div class="col-md-12 form-group">
                                    <label class="control-label">Jadwal</label>
                                    <v-select :options="optJadwal" v-model="form.jadwal_id" :reduce="opt => opt.id" :key="id"/>
                                </div>
                                <div class="col-md-8 form-group">
                                    <label class="control-label">Tanggal</label>
                                    <datepicker v-model="form.tanggal" format='DD-MM-YYYY'/>
                                </div>
                                <div class="row" style="padding: 7.5px;">
                                    <div class="col-md-6 form-group">
                                        <label class="control-label">Mulai</label>
                                        <timepicker  v-model="form.waktu_start" />
                                    </div>
                                    <div class="col-md-6 form-group">
                                        <label class="control-label">Selesai</label>
                                        <timepicker  v-model="form.waktu_end" />
                                    </div>
                                </div>
                                <div class="col-md-8 form-group">
                                    <label class="control-label">Materi</label>
                                    <textarea class="form-control" v-model="form.materi" ></textarea>
                                </div>
                                <div class="col-md-8 form-group">
                                    <label class="control-label">Soal</label>
                                    <textarea class="form-control" v-model="form.soal" ></textarea>
                                </div>
                                <div class="col-md-8 form-group">
                                    <label class="control-label">Keterangan</label>
                                    <textarea class="form-control" v-model="form.keterangan" ></textarea>
                                </div>
                                <div class="col-md-8 form-group">
                                    <label class="control-label">Catatan Admin</label>
                                    <textarea class="form-control" v-model="form.catatan_admin" ></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="submit" class="btn btn-primary">Save changes</button>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { createTable, authFetch } from '@/libs/hxcore';
import $ from 'jquery';
import vSelect from 'vue-select';
import "vue-select/dist/vue-select.css";
import datepicker from '@/components/Datepicker';
import timepicker from '@/components/Timepicker';
import Swal from "sweetalert2";

export default {
    name: 'JurnalGuru',
    components: {
        vSelect,
        timepicker,
        datepicker,
    },
    data() {
        return {
            errors: [],
            method: '',
            roles: '',
            groups: [],
            optJadwal: [],
            formTitle: 'Tambah',
            id : 0,
            form: {
                id: '',
                jadwal_id: '',
                tanggal: '',
                waktu_start: '',
                waktu_end: '',
                materi: '',
                soal: '',
                keterangan: '',
                catatan_admin: ''
            }
        }
    },
    created: function() {
        this.roles = this.$route.meta.roles;
        this.id = this.$route.params.id;

        authFetch('/administrasi/jurnal_guru/jadwal_by_guru/'+this.id)
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                this.optJadwal = js.data;
            })
        authFetch('/akademik/data_guru/'+this.id)
            .then(res => {
                if (res.status === 201) {

                } else if (res.status === 400) {}
                return res.json();
            })
            .then(js => {
                $('.card-title').html('Nama Guru : '+js.name)

            })
    },
    methods: {
        handleClick(e) {
            if (e.target.matches('.link-role')) {
                this.$router.push({ path: '/permit/' + e.target.dataset.id })
                return false;
            }
        },
        search: function() {
            this.table.api().ajax.reload();
        },
        submitForm: function(ev) {
            const e = this.$refs;
            var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
            var urlSubmit = '/administrasi/jurnal_guru';
            if (this.method == 'PUT') urlSubmit = '/administrasi/jurnal_guru/' + this.form.id;

            authFetch(urlSubmit, {
                    method: this.method,
                    body: data
                })
                .then(res => {
                    if (res.status === 201) {

                    } else if (res.status === 400) {}
                    return res.json();
                })
                .then(js => {

                    this.errors = [];
                    if (!js.success) {
                        console.log(js.details)

                        for (var key in js.details) {
                            if (js.details.hasOwnProperty(key)) {
                                this.errors.push(js.details[key])
                            }
                        }

                        return;
                    }
                    this.table.api().ajax.reload();
                    $(e.formDialog).modal('hide');
                    authFetch('/administrasi/jurnal_guru/jadwal/'+this.id)
                    .then(res => {
                        if (res.status === 201) {

                        } else if (res.status === 400) {}
                        return res.json();
                    })
                    .then(js => {
                        this.optJadwal = js.data;
                    })
                });

            ev.preventDefault();
        }
    },
    mounted() {
        const e = this.$refs;
        var self = this;
        this.table = createTable(e.tableuser, {
            "title": "List Jurnal",
            "roles": this.$route.params.roles,
            "ajax": "/administrasi/jurnal_guru/guru/"+this.id,
            "columns": [
                { "data": "code" },
                { "data": "hari" },
                { "data": "tanggal_formated" },
                { "data": "materi" },
                { "data": "materi" },
                { "data": "keterangan" },
            ],
            filter:false,
            filterBy: [0 ,1, 2],
            "rowCallback": function(row, data) {
                $('td:eq(3)', row).html(data.waktu_start+' - '+data.waktu_end);
            },
            "paramData": function ( d ) {
                // d.tentor = $('.f_tentor').val();
                // d.kelas = $('.f_kelas').val();
                // d.mapel = $('f_.mapel').val();
                // d.tanggal = $('.f_tanggal').val();
            },
            buttonClick: (evt) => {
                if (evt.role == 'create') {
                    self.form = {};
                    self.method = 'POST';
                    self.errors = [];
                    self.formTitle = 'Tambah Jurnal Guru';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'update' && evt.data) {
                    self.form = evt.data;
                    self.method = 'PUT';
                    self.errors = [];
                    self.formTitle = 'Edit Jurnal Guru';
                    $(e.formDialog).modal('show');
                } else if (evt.role == 'delete' && evt.data) {
                    self.form = evt.data;
                    Swal.fire({
                        title: "Hapus data?",
                        icon: "question",
                        denyButtonText: '<i class="fa fa-times"></i> Hapus',
                        showCancelButton: true,
                        showDenyButton: true,
                        showConfirmButton: false,
                      }).then((result) => {
                        if (result.isDenied) {
                            authFetch('/administrasi/jurnal_guru/' + evt.data.id, {
                                method: 'DELETE',
                                body: 'id=' + evt.data.id
                            })
                            .then(res => {
                                return res.json();
                            })
                            .then(js => {
                                this.table.api().ajax.reload();
                            });
                        }
                      });
                }

            },
        });
    }
}
</script>
<style scoped>
th {
  text-transform: uppercase;
}
</style>